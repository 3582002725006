<template>
  <div class="main">
    <div class="header">
      <div class = "logo">DAN'S REPAIR TECH SERVICES</div>
    </div>

    <div class = "body">
      <div>
        <h1>Tech Support Based in the US</h1>
        <h4>Speak with someone who understands you and your problem - not someone who is reading a script</h4>
      </div>

      <div class = "services">
        <h1>Expert Tech Repair Services</h1>
        <h4>To get started with fixing your problem, all you have to do is answer a few simple questions</h4>
        <h4>Don't want to answer questions online?  Fair!  Give us a call or text:</h4>
        <div>
          <a href="tel:8103283419">(810) 328-3419</a>
        </div>
        <div>
          <Questionnaire />
        </div>
      </div>

      <div>
        <h1>St. Clair County Local Business</h1>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.4000752926913!2d-82.5161848841593!3d42.67406697916693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88256babec8463a7%3A0xefb3759748aa86f3!2sDan&#39;s%20Tech%20Services!5e0!3m2!1sen!2sus!4v1612310369003!5m2!1sen!2sus" width="80%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import Questionnaire from '../components/Questionnaire'

export default {
  name: 'Main',
  components: {
    Questionnaire
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main{
    color: rgb(40,40,40);
  }

  a{
    text-decoration: none;
    display: block;
    color: rgb(220,220,220);
    font-size: 24px;
    background-color: rgb(40,40,40);
    border-radius: 13px;
    width: 25%;
    padding: 20px 0;
    margin: 20px auto;
  }

  .header{
    position: relative;
    width: 100%;
    height: 100px;
    color: rgb(40,40,40);
  }

  .logo{
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 2em;
    top: 1%;
    width: 100%;
    text-decoration: none;
  }

  .body{
    width: 80%;
    margin: auto;
  }

  .service{
    display: inline-block;
    vertical-align: top;
    padding: 20px;
    border: 1px solid rgb(40,40,40);
    border-radius: 13px;
    width: 25%;
    margin: 0 10px;
  }

  @media screen and (max-width: 1320px) {
    .logo{
      position: relative;
      text-align: center;
      width: 100%;
      top: 3%;
    }
    .service{
      display: block;
      padding: 20px;
      border: 1px solid rgb(40,40,40);
      border-radius: 13px;
      width: 80%;
      margin: 10px auto;
    }

    a{
      width: 90%;
    }

}
</style>