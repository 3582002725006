<template>
  <div class="questions">
    <div class = "question" v-for="q in question" :key="q.id" v-bind:class="{ hidden : q.id != currentquestion }">
      <h1>{{q.text}}</h1>

      <div v-for="a in answers[q.id]" :key="a.id" v-bind:class="{ 'button' : a.type == 'button' }" @click="nextQuestion(a.label)">
        <div>{{a.label}}</div>
      </div>
    </div>
    <form v-if="currentquestion == 3">
      <h3>Finally, we just need some information to contact you back:</h3>
      <input v-model="name" type="text" placeholder="Contact Name"/>
      <input v-model="email" type="email" placeholder="Email Address"/>
      <input v-model="phone" type="tel" placeholder="Phone Number" />
      <input v-model="description" type="text" placeholder="Short Description of Problem"/>
      <h3>Good Time to Call:</h3>
      <input v-model="time" type="time" />
    </form>
    <div class = "button forward" v-if="currentquestion == 3" @click="submit()">Submit</div>
    <h3 v-if="currentquestion == 4">Thank You!  We Will Get Back to You as Soon as Possible!</h3>
    <div class = "button back" v-if="currentquestion != 0" @click="startOver()">Start Over</div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'Questions',
  data: function(){
    return{
      currentquestion : 0,
      name : "",
      email : "",
      phone : "",
      description : "",
      time:"",
      question: [
        {id : 0, text: "What is the Tech Needing Fixing?",  response : ""},
        {id : 1, text: "What Type of Problem Do You Have?", response : ""},
        {id : 2, text: "Was it Serviced Before?",           response : ""}
      ],
      answers: [
        [
          {id : 1, type : "button", resp: "computer", label : "PC / Laptop"},
          {id : 2, type : "button", resp: "handheld", label : "Phone / Tablet"},
          {id : 3, type : "button", resp: "screen",   label : "Monitor / Television"},
          {id : 4, type : "button", resp: "other",    label : "Other"}
        ],
        [
          {id : 1, type : "button", resp: "turnon",   label : "Doesn't Turn On"},
          {id : 2, type : "button", resp: "slow",     label : "Is Running Slowly"},
          {id : 3, type : "button", resp: "broken",   label : "Has Broken Parts"},
          {id : 4, type : "button", resp: "other",    label : "Other"}
        ],
        [
          {id : 1, type : "button", resp: "yes",      label : "Yes"},
          {id : 2, type : "button", resp: "no",       label : "No"}
        ]
      ]
    }
  },
  methods:{
    nextQuestion(label){
      this.question[this.currentquestion].response = label;
      this.currentquestion++;
    },
    startOver(){
      for(let i = 0; i < this.question.length; i++){
        this.question[i].response = "";
      }

      this.name = "",
      this.email = "",
      this.phone = "",
      this.description = "",
      this.currentquestion = 0;
      this.time = "";
    },
    submit(){
      emailjs.send("service_6bny2mb","template_6jxd22a", {
        from_name: this.name,
        to_name: "Tech Team",
        device: this.question[0].response,
        issue: this.question[1].response,
        serviced: this.question[2].response,
        reply_to: this.email,
        reply_phone: this.phone,
        message: this.description,
        time: this.time
      }, 'user_VP8bjHnr8NPvSZbzty10W');
      this.currentquestion++;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button{
  width: 20%;
  margin: 5px;
  display: inline-block;
  background-color: rgb(220, 220, 220);
  padding: 40px 20px;
  border-radius: 13px;
  font-size: 22px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

input{
  border: 3px solid rgb(220,220,220);
  border-radius: 13px;
  padding: 20px;
  width: 50%;
  margin: 5px auto;
  text-align: center;
}

.hidden{
  opacity: 0;
  width: 0;
  height: 0;
  float: left;
}

.forward{
  display: block;
  margin: 10px auto;
  background-color: rgb(40,40,40);
  color: rgb(220, 220, 220);
}

.back{
  border: 5px solid rgb(220, 220, 220);
  background-color: rgb(255, 210, 150);
}

.questions{
  position: relative;
  top: 0;
}

.button:hover{
  background-color: rgb(190, 190, 190);
}

@media screen and (max-width: 1320px) {
  .button{
    display: block;
    width: 80%;
    margin: 5px auto;
  }

  input{
    width: 80%;
  }
}
</style>
